.landingPageBanner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: 768px) {
    height: auto;
    aspect-ratio: 1440 / 680;
  }
}
